<template>
  <div id="wrapper">
    <header>
      <b-navbar toggleable="lg" type="light" variant="transparent" fixed="top" :sticky='true'>
        <b-navbar-brand href="#">TheHappyClouds</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="m-auto">
            <b-nav-item href="#">Whitelist</b-nav-item>
            <b-nav-item href="#">Mint</b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav>
            <b-nav-item><meta-mask v-on:walletConnected="updateAccount" v-on:walletDisconnected="updateAccount" v-on:networkIdChanged="updateChainID"></meta-mask></b-nav-item>
            <b-nav-item v-if="accountConnected()"><mint :account="accountChanged"></mint></b-nav-item>
            <b-nav-item v-else>Connect your wallet and set network to ID 4-Rinkeby</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </header>
    <b-container fluid class="text-center mt-5 h-100" id="main">
      <b-card-group columns>
        <b-card v-bind:img-src='imgURL + "3.png"' img-alt="Image" overlay></b-card>
        <b-card v-bind:img-src='imgURL + "13.png"' img-alt="Image" overlay></b-card>
        <b-card v-bind:img-src='imgURL + "5.png"' img-alt="Image" overlay></b-card>
        <b-card v-bind:img-src='imgURL + "6.png"' img-alt="Image" overlay></b-card>
        <b-card v-bind:img-src='imgURL + "8.png"' img-alt="Image" overlay></b-card>
        <b-card v-bind:img-src='imgURL + "9.png"' img-alt="Image" overlay></b-card>
      </b-card-group>
    </b-container>
    <footer>

    </footer>
  </div>
</template>

<script>

  import MetaMask from './MetaMask.vue'
  import Mint from './Mint.vue'

  export default {
    name: 'Home',
    components: {MetaMask, Mint},
    data () {
      return {
        imgURL: process.env.VUE_APP_URL + '/img/',
        account: null,
        chainId: null
      }
    },
    computed: {
      accountChanged: function () {
        return this.account
      },
      chainIdChanged: function () {
        return this.chainId
      }
    },
    methods: {
      updateAccount: function (account) {
        this.account = account;
      },
      updateChainID: function (chainId) {
        this.chainId = chainId
      },
      accountConnected: function () {
        if (window.ethereum) {
          if (this.account !== null && this.chainId == 4) {
            return true
          }
          return false
          }
          return false
        }
    }
  }
</script>

<style>

</style>


const config = {
  localURL : process.env.VUE_APP_URL || 'http://localhost:8081'
}

export {
  config
}

export default {
  install (Vue) {
    Vue.appConfig = config
    Vue.prototype.$appConfig = config
  }
}

<template>
  <b-button variant="success" id="connectButton">{{this.btnConnectText}}</b-button>
</template>

<script>

  import MetaMaskOnboarding from '@metamask/onboarding'

  export default {
    name: 'Connect',
    emits: ['walletConnected', 'walletDisconnected', 'networkIdChanged'],
    data () {
      return {
        currentAccount: null,
        currentChainId: null,
        btnConnectText: "Connect wallet",
        hasInstalled: false,
        errors: [],
        message: null,
        chainIds: {
          1: "Mainnet",
          3: "Ropsten",
          4: "Rinkeby",
          5: "Goerli",
          42: "Kovan",
          56: "Binance",
          137: "Polygon"
        },
        isProviderListening: false
      }
    },
    computed: {
      getCurrentAccount: function () {
        return this.currentAccount
      },
      getCurrentNetwork: function () {
        return this.currentChainId + " - " + this.chainIds[this.currentChainId]
      },
      getCurrentChainId: function () {
        return this.currentChainId
      }
    },
    mounted () {
      this.MetaMaskClientCheck()
    },
    unmounted () {
      if (this.isMetaMaskInstalled() && this.isProviderListening === true) {
        this.removeProviderListeners()
      }
    },
    methods: {
      isMetaMaskInstalled: function () {
        const { ethereum } = window
        return Boolean(ethereum && ethereum.isMetaMask)
      },
      MetaMaskClientCheck: function () {
        var connectButton = document.getElementById('connectButton');
        if (!this.isMetaMaskInstalled()) {
          this.btnConnectText = "Click here to install MetaMask"
          connectButton.onclick = this.onClickInstall
          connectButton.disabled = false
        }
        else {
          if (this.isProviderListening === false) {
            this.setProviderListeners()
          }
          this.getConnectedAccount()
          this.getChainId()
          if (this.getCurrentAccount === null) {
            this.btnConnectText = "Connect your wallet"
            connectButton.onclick = this.onClickConnect
            connectButton.disabled = false
          }
          else {
            this.btnConnectText = this.currentAccount
            connectButton.disabled = true
          }
        }
      },
      onClickInstall: function () {
        console.log('click install')
        // var forwaderOrigin = this.forwaderOrigin
        var connectButton = document.getElementById('connectButton')
        const onboarding = new MetaMaskOnboarding()
        this.btnConnectText = "Onboarding in progress"
        connectButton.disabled = true
        onboarding.startOnboarding()
        if (this.isProviderListening === false) {
          this.setProviderListeners()
        }
      },
      onClickConnect: function () {
        console.log('click connect')
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then(this.onAccountsChanged)
          .catch(error => {
            this.errors.push(error)
            console.log(error)
          })
      },
      onDisconnect: function () {
        console.log('disconnected')
      },
      onConnect: function (connectInfo) {
        console.log('connect')
        this.currentChainId = parseInt(connectInfo.chainId)
      },
      onAccountsChanged: function (accounts) {
        console.log('account changed')
        var connectButton = document.getElementById('connectButton');
        if (accounts.length === 0) {
          this.currentAccount = null
          connectButton.disabled = false
          this.btnConnectText = "Connect your wallet"
          this.$emit('walletDisconnected', null)
        }
        else if (this.currentAccount !== accounts[0]) {
          this.currentAccount = accounts[0]
          connectButton.disabled = true
          this.btnConnectText = this.currentAccount
          this.$emit('walletConnected', this.currentAccount);
        }
      },
      onChainChanged: function (chainId) {
        console.log('chain changed')
        this.currentChainId = parseInt(chainId)
        if (parseInt(chainId) !== 1) {
          this.message = "You are connected to a test network or other chain"
        }
        this.$emit('networkIdChanged', this.currentChainId)
      },
      setProviderListeners: function () {
        window.ethereum.on('accountsChanged', this.onAccountsChanged)
        window.ethereum.on('chainChanged', this.onChainChanged)
        window.ethereum.on('connect', this.onConnect)
        this.isProviderListening = true
        console.log('listener set')
      },
      removeProviderListeners: function () {
        window.ethereum.removeListener('accountsChanged', this.onAccountsChanged)
        window.ethereum.removeListener('chainChanged', this.onChainChanged)
        window.ethereum.removeListener('connect', this.onConnect)
        this.isProviderListening = false
        console.log('listener removed')
      },
      getConnectedAccount: function () {
        console.log('get account')
        window.ethereum
          .request({ method: 'eth_accounts' })
          .then(this.onAccountsChanged)
          .catch(error => {
            this.errors.push(error)
            console.log(error)
          })
      },
      getChainId: function () {
        console.log('get chain ID')
        window.ethereum
          .request({method: 'eth_chainId'})
          .then(this.onChainChanged)
          .catch(error => {
            this.errors.push(error)
          })
      },
      reloadPage: function () {
        window.location.reload()
      }
    }
  }

</script>

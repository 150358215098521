<template>
  <div>
    <b-button variant="primary" id="mintButton" v-on:click='mintNFT'>Mint NFT</b-button>
  </div>
</template>

<script>

  import Web3 from 'web3'

  export default {
    name: 'Mint',
    props: [
      'account'
    ],
    data () {
      return {
        contract: process.env.VUE_APP_SMART_CONTRACT
      }
    },
    mounted () {

    },
    methods: {
      mintNFT: function () {
        var abiData = this.abiEncode()
        if (abiData == "") {
          console.log('encoding issue')
          return
        }
        if (this.account === null) {
          console.log('Account not connected')
          return
        }
        window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [{
            from: this.account,
            to: this.contract,
            data: abiData
          }],
        })
          .then(function (txHash){
            alert('Successful mint! You can see the transaction: https://rinkeby.etherscan.io/tx/' + txHash)
          })
          .catch(function (error){
            console.log(error)
          })
      },
      abiEncode: function () {
        var web3 = new Web3(Web3.givenProvider)
        var abi = ""
        abi = web3.eth.abi.encodeFunctionCall({
          name: 'mintNFT',
          type: 'function',
          inputs: [{
            type: 'address',
            name: 'to'
          }]
        }, [this.account])
        return abi
      },
      getAccount: function () {
        var web3 = new Web3(Web3.givenProvider)
        var account = null
        web3.eth.getAccounts()
        .then(function (response) {
          account = response[0]
        })
        .catch(function (error) {
          console.log(error)
        })
        return account
      }
    }
  }
</script>
